import React, { FC, useEffect, useRef } from 'react';
import { css } from 'twin.macro';

type BloomerangProps = {};

export const Bloomerang: FC<BloomerangProps> = (props) => {
  const container = useRef<HTMLDivElement>(null);
  //const loaded = useRef(false);

  useEffect(() => {
    const _container = container.current;
    let script: HTMLScriptElement | null = null;

    if (window && document && _container && !script) {
      script = document.createElement('script');
      script.src = `https://s3-us-west-2.amazonaws.com/bloomerang-public-cdn/internationalcenterforadvocatesagainstdiscrimination/.widget-js/1901568.js`;
      _container.appendChild(script);
    }

    return () => {
      // Cleanup all the HTML it inserts
      while (_container?.lastChild) {
        _container.removeChild(_container.lastChild);
      }

      // Cleanup what it might insert into the global scope
      delete window['Bloomerang'];
      delete window['bloomerangLoadStarted'];

      // Cleanup any previous stripe instances
      const stripeResidue = document.querySelectorAll(
        'iframe[name^="__privateStripe"]',
      );
      stripeResidue.forEach((el) => el.parentNode?.removeChild(el));
    };
  }, []);

  return (
    <div
      ref={container}
      css={[
        css`
          h3 {
            font-size: 19px;
            margin-top: 3rem;
            text-decoration: underline;
          }
          .donation h3 {
            margin-top: 0;
          }
          input[type='text'],
          input[type='email'],
          input[type='tel'],
          input[type='number'],
          #card-element,
          select,
          textarea {
            border: solid 2px rgb(var(--brand-gray-light));
            min-height: 2rem;
          }
          input.error,
          select.error,
          textarea.error {
            border: solid 2px rgb(var(--brand-coral)) !important;
          }
          select {
            min-height: 0;
            padding-top: 6px;
            padding-bottom: 6px;
          }
          input[type='checkbox'] {
            margin-right: 0.5rem;
          }
          input[type='submit'] {
            background-color: rgb(var(--brand-coral));
            color: white;
            padding: 0.5rem 2rem !important;
            cursor: pointer;
          }
          /* overrides on the injected code */
          #card-element {
            padding: 0.5rem;
          }
          .donation-form .section.recurring {
            padding-left: 0;
          }
          .donation-form .field input,
          .donation-form .field select,
          .donation-form .field textarea,
          .donation-form #card-element {
            max-width: 32rem;
          }
          .donation-form .field {
            margin-bottom: 0.5rem;
          }
          .captcha {
            margin-bottom: 1rem;
          }
          .donation-form label.error,
          .donation-form #card-errors {
            color: rgb(var(--brand-red-delicious));
            display: block;
            padding: 0;
          }
        `,
      ]}
    ></div>
  );
};
