import { Box, Flex, HStack, Text } from '@chakra-ui/react';
import { PageProps } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import React, { FC, useEffect, useMemo, useState } from 'react';
import { isMobile } from 'react-device-detect';
import ReactMarkdown from 'react-markdown';
import { useSwipeable } from 'react-swipeable';
import tw, { styled, theme } from 'twin.macro';
import { Bloomerang } from '~/components/bloomerang';
import {
  headingCss,
  LinkPlus,
  PageContainer,
  Highlight,
  highlightProps,
} from '~/components/common';
import { Layout } from '~/components/common/layout/Layout';
import { SEO } from '~/components/SEO';
import { CustomCssProps, Frontmatter, isSSG } from '~/config';
import { useDonatePageQuery } from '~/hooks/useDonatePageQuery';
import { SponsorLevel, useSponsorQuery } from '~/hooks/useSponsorQuery';

// don't add export - uses local gatsby plugin
// eslint-disable-next-line
const frontmatter: Frontmatter = {
  title: 'Donate',
  order: 3,
};

const levelLabels = {
  '00:platinum-plus': 'Platinum Plus',
  '01:platinum': 'Platinum',
  '02:gold': 'Gold',
  '03:silver': 'Silver',
  '04:bronze': 'Bronze',
  '05:partner': 'Partner',
} as const;

const Page: FC<PageProps> = (props) => {
  const { donateContent, donateHeading, carouselData } = useDonatePageQuery();
  const { individualSponsorsByLevel } = useSponsorQuery();
  const [currentSlide, setCurrentSlide] = useState(0);

  let slides = carouselData;

  const slidesCount = slides.length;

  const setSlide = (slide) => {
    setCurrentSlide(slide);
  };

  const swipeHandlers = useSwipeable({
    onSwipedLeft: () =>
      currentSlide + 1 < slidesCount && setSlide(currentSlide + 1),
    onSwipedRight: () => currentSlide > 0 && setSlide(currentSlide - 1),
  });

  const carouselStyle = {
    transition: 'all .5s',
    ml: `-${currentSlide * 100}%`,
  };

  // memoized to prevent re-rendering
  const markdownConfig = useMemo(
    () => ({
      p: (props) => <P>{props.children}</P>,
      a: (props) => (
        <LinkPlus {...props} to={props.href} customCss={tw`underline`}>
          {props.children}
        </LinkPlus>
      ),
    }),
    [],
  );

  const description = donateContent.split('\n')?.[0];

  return (
    <Layout footer>
      <SEO title={frontmatter.title} description={description} path="/donate" />
      <PageContainer>
        <PageColumn>
          <ContentSection>
            <Highlight {...highlightProps} bgColor={theme`colors.brand-sea`}>
              <H2>{donateHeading}</H2>
            </Highlight>
            <ReactMarkdown components={markdownConfig}>
              {donateContent}
            </ReactMarkdown>
            <DonateSection>
              <Bloomerang />
            </DonateSection>
            <SponsorsSection>
              <Highlight
                {...highlightProps}
                delay={1}
                bgColor={theme`colors.brand-sky-blue`}
                customCss={[highlightProps.customCss, tw`mb-2`]}
              >
                <H2 tw="pb-2">Individual sponsors</H2>
              </Highlight>
              {individualSponsorsByLevel.map(
                (level) =>
                  level.length > 0 && (
                    <SponsorList
                      key={level[0].level}
                      level={level[0].level as SponsorLevel}
                    >
                      <H3 level={level[0].level as SponsorLevel}>
                        {levelLabels[level[0].level]}
                      </H3>
                      {level.map((sponsor) => (
                        <p key={sponsor.slug}>{sponsor.title}</p>
                      ))}
                    </SponsorList>
                  ),
              )}
            </SponsorsSection>
          </ContentSection>

          {true && (
            <CarouselSection tw="flex flex-col md:([max-width:600px] px-8) py-2.5">
              {/* Carousel slides */}
              <Flex
                w="full"
                overflow="hidden"
                pos="relative"
                {...swipeHandlers}
              >
                <Flex h="400px" w="full" {...carouselStyle}>
                  {slides.map((slide, sid) => (
                    <Box
                      key={`slide-${sid}`}
                      boxSize="full"
                      display="flex"
                      flexShrink={0}
                    >
                      <GatsbyImage
                        image={
                          slide.carousel_image.image.childImageSharp
                            .gatsbyImageData
                        }
                        alt={slide.carousel_image.image_alt}
                        tw="[object-fit:cover]"
                      />
                    </Box>
                  ))}
                </Flex>
              </Flex>
              {/* Image captions */}
              <Flex
                flexDir="column"
                w="100%"
                justifyContent="center"
                alignItems="start"
                lineHeight={1.1}
                mt={3}
              >
                {
                  // Formatting the text here so as not to mess
                  // with the CMS data structure right now
                  carouselData[currentSlide].image_caption
                    ?.replace(/##/g, '')
                    .split('Artist:')
                    .map((cap, i) => (
                      <Text
                        key={cap}
                        color="#5c5c5c"
                        fontSize={i === 0 ? 'md' : 'sm'}
                        ml={0}
                        css={i === 0 && tw`font-bold`}
                        mt={1}
                      >
                        {i === 1 && 'Artist: '}
                        {cap}
                      </Text>
                    ))
                }
              </Flex>
              {/* Carousel dot controls */}
              <HStack justify="center" w="full" pt={8}>
                {Array.from({ length: slidesCount }).map((_, slide) => (
                  <Box
                    key={`dots-${slide}`}
                    cursor="pointer"
                    boxSize={['7px', '15px']}
                    m="0 2px"
                    bg={currentSlide === slide ? '#A53686' : '#5c5c5c'}
                    rounded="50%"
                    display="inline-block"
                    transition="background-color 0.6s ease"
                    _hover={{ bg: '#A53686' }}
                    onClick={() => setSlide(slide)}
                  />
                ))}
              </HStack>
            </CarouselSection>
          )}
        </PageColumn>
      </PageContainer>
    </Layout>
  );
};

const PageColumn = styled.div<CustomCssProps>(({ customCss }) => [
  tw`block m-auto [max-width:1440px] overflow-hidden md:(px-4) lg:(flex align-top p-0)`,
  customCss,
]);
const ContentSection = tw.section`md:(pt-3 px-8 pb-6)`;
const SponsorsSection = tw.section`pt-4 pb-16 mb-0.5`;
const DonateSection = tw.section`mt-6 md:(h-72 overflow-y-auto)`;
const CarouselSection = tw.section`flex flex-col`;
const SponsorList = styled.div<{ level: SponsorLevel }>(({ level }) => [
  tw`text-gray-600 text-sm tracking-wide px-4 pt-2 pb-6 mb-0.5 bg-[#f9f9f9] [border-left-width: 5px]`,
  level === '00:platinum-plus' && tw`border-l-brand-sky-blue`,
  level === '01:platinum' && tw`border-l-[#e8e8e8]`,
  level === '02:gold' && tw`border-l-[#f0e7d2]`,
  level === '03:silver' && tw`border-l-[#dbdbdb]`,
  level === '04:bronze' && tw`border-l-[#dfd3c2]`,
  level === '05:partner' && tw`border-l-[#f1e0ed]`,
]);
const P = tw.p`mb-4`;
const H2 = styled.h2(() => [headingCss]);
const H3 = styled.div<{ level: SponsorLevel }>(({ level }) => [
  tw`mb-2 font-bold uppercase text-base`,
  level === '00:platinum-plus' && tw`text-brand-sky-blue`,
  level === '01:platinum' && tw`text-brand-gray`,
  level === '02:gold' && tw`[color:#A3894D] `,
  level === '03:silver' && tw`[color:#8A8A8A]`,
  level === '04:bronze' && tw`[color:#DC9F69]`,
  level === '05:partner' && tw`text-brand-coral`,
]);

export default Page;
