import { graphql, useStaticQuery } from 'gatsby';
import { useMemo } from 'react';
import { DonatePage } from '~/config/types';
import { frontmatterWithSlug } from '~/utils';

export const useDonatePageQuery = () => {
  const data = useStaticQuery(graphql`
    {
      donatePage: allMarkdownRemark(
        filter: { fileAbsolutePath: { regex: "/pages/donate/" } }
        sort: { fields: [frontmatter___level, frontmatter___order], order: ASC }
      ) {
        edges {
          node {
            frontmatter {
              heading
              content
              carousel {
                image_caption
                carousel_image {
                  image {
                    publicURL
                    extension
                    childImageSharp {
                      gatsbyImageData
                    }
                  }
                  image_alt
                }
              }
            }
            fileAbsolutePath
          }
        }
      }
    }
  `);

  return useMemo(() => {
    const donateNodes = frontmatterWithSlug<Partial<DonatePage>>(
      data.donatePage,
    );
    const donateData = donateNodes[0]! as DonatePage;
    const carouselData = donateData.carousel;
    const donateContent = donateData.content;
    const donateHeading = donateData.heading;
    return { donateContent, donateHeading, carouselData };
  }, [data]);
};
